import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserLoginComponent } from './user-login/user-login.component';
import { AppGuard } from './app.guard';

const routes: Routes = [
{ path: '', component: UserLoginComponent },
    // { path: '', loadChildren: './user/user-landing-page/user-landing-page.module#UserLandingPageModule',  },
    { path: 'user', loadChildren: './user/user-landing-page/user-landing-page.module#UserLandingPageModule',  },

    { path: 'userlogin', component: UserLoginComponent },
    { path: 'userlogin/:data', component: UserLoginComponent },
    { path: 'receiptdownload', loadChildren: './receipt/receipt.module#ReceiptModule' },
    { path: 'userregistration', loadChildren: './user-registration/user-registration.module#UserRegistrationModule' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule]
})

export class AppRoutingModule { }
