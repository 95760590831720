import { ReceiptHeaderImage } from './Extras/receipt-header-image';

export class StaticVariable {

    static porturl = 'http://148.66.133.79:8080/DevDemoBookingApi/';

    // static porturl = 'http://192.168.43.214:8080/DevDemoBookingApi/'; 

    //  static porturl = 'http://e148.66.133.79:8080/ThiruvanthipuramDevDemoBookingApi/';

    static TempleName = 'ARULMIGU DEVANATHA SWAMY TEMPLE';
    static District = 'Tiruvahindipuram';
    static Pincode = '607 401';
    static Email = '';
    static receiptBanner = ReceiptHeaderImage.receiptBanner;
    static status_1 = 'INITIAL';
    static status_2 = 'SUCCESS';
    static status_3 = 'FAILED';
    static status_4 = 'PENDING';
    static smsEncryptOffset = 25;
    static technicalSupport = '';
    static mail1 = '';
    static mail2 = '';
    static landLine = '04142 287 515';
    static tollFree = '';
    static pgKey1 = 'SxwgqL';
    static pgKey2 = '';
    static pgSalt1 = 'zC7VUYYY';
    static pgSalt2 = '';

}


